.product-slider img {
  width: 250px;
  margin: 10px;
}

.main-image-crousel img {
  width: 95% !important;
  /* padding: 05px; */
  height: 420px;
  border-radius: 6px;
}

.preview-container img {
  width: 95% !important;
  /* padding: 05px; */
  height: 120px;
}
/* Add this CSS to position the navigation arrows */
.owl-carousel .owl-nav {
  position: absolute;
  top: 36%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 55px;
  color: white;
}

.owl-carousel .owl-nav .owl-prev {
  order: -1; /* Move the previous arrow to the left */
  background: transparent;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  width: 20%;
}

.owl-carousel .owl-nav .owl-next {
  order: 1; /* Move the next arrow to the right */
  background: transparent;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  width: 20%;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
  color: white;
}
/* Optionally, you can hide the arrows on small screens */
@media screen and (max-width: 768px) {
  .owl-carousel .owl-nav {
    display: none;
  }
}

.active-ordered {
  justify-content: left;
}
.active-ordered div {
  background-color: #f8f8f8;
  font-size: 20px;
}
.price-title-off {
  border-bottom: 1px dotted gray;
}

.percentage-off {
  color: white;
  border-radius: 50px;
  background: red;
  height: 45px;
  width: 45px;
  text-align: center;
  padding: 3px;
  /* position: absolute; */
  /* right: 34%; */
  font-size: 15px;
}

.ebook-description p{
  text-align: center;
  justify-content: center;
}

.ebook-description span {
  color: #8e8bf5;
  font-weight: bold;
}

.ebook-description .hurry-up img{
  width: 25px;
  height: 25px;
  align-items: center;
}

.payment-link a{
  width: 100%;
}
.payment-link button {
  border: none;
  background: linear-gradient(to right top,#FED300,#FFEF9E);
  border-radius: 11px;
  font-weight: bold;
  padding: 15px 52px;
  color: black;
  font-size: 20px;
  width: 100%;
}
.payment-link button  img{
  width: 25px;
  
}
.payment-link button:hover {
  transform: scale(1);
  transition: transform 0.3s ease;
  background: black !important;
  color: white;
}



.product-cards{
  box-shadow: 0px -2px 20px 11px #cac9c9;
  border-radius: 15px;
  
}
.product-cards ul{
  padding: 5px;
  height: 282px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.product-cards ul img {
  width: 25px;
}

.product-cards ul li {
  list-style-type: none;
  margin-top: 12px;
  font-size: 13px;
  font-weight: bold;
  width: 50%;
}
.product-cards h4 {
  background: #8e8bf5;
  text-align: center;
  color: white;
  padding: 15px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.crousel-each-product img {
  height: 100%;
  background-color: #d1dfec;
  width: 100%;
  max-height: 300px;
}
.zoomable {
  transition: transform 0.5s;
  cursor: pointer;
}

.zoomed-in {
  transform: scale(2); 
  width: 100% !important;
}


.each-detail-product .each-title{
  border-bottom: 1px dotted gray;
  padding-bottom: 15px;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: bold;
}

.each-title span {
  background: linear-gradient(90deg, #E02527 31.18%, #F8CD00 80.48%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.each-title p{
  font-size: 15px;
  color: #4d4d4db8;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 10px;
}
.why-buy-this{
  padding: 0;
}
button.btn.clickToShowMore {
  /* width: 100%; */
  margin-top: -15px;
  background: white;
  float: inline-end;
  width: 85px;
  box-shadow: 9px 20px 20px 6px #cac9c9;

}
.clickToShowMore img {
  /* animation: bounce 2s infinite; */
  /* z-index: 555; */
}
.masterProductDetail{
  background: linear-gradient(to bottom, #e0e8f6, #ffffff);
}
.masterProductDetail .category .product2{
  background: linear-gradient(180deg,#e0ffd8 0,#f9fff7 51.07%,#fff 100%);
} 
.masterProductDetail .category .product1{
  background: linear-gradient(180deg, #f7e6ff 0, #fefcff 54.31%, #fff 100%);
} 
.masterProductDetail .category .product1,.product2{
  border-radius: 35px;
}
.masterProductDetail .bottom-banner{
  background: linear-gradient(to right, #605dc9 0, #817fe6 75%);
  border-radius: 35px;
}
.masterProductDetail .bottom-banner .offerDiscount .offerHeading h2{
  font-weight: bold;
  font-size: 55px;
}
/* .masterProductDetail .bottom-banner .CreativeImg img{
  top: 0;
  position: relative;
} */
.who-for-inner{
display: flex;
flex-wrap: wrap;
}
.who-for-inner .col-4{
  display: flex;
  gap: 10px;
  margin: 15px 0;
  /* align-items: center; */
}
.who-for-inner .hustler h3{
  font-weight: bold;

  font-size: 20px;
}
.who-for-inner div .icon{
  width: 26px;
  height: 26px;
}
.who-for-inner .hustler .hustlerImg{
  background: linear-gradient(137deg,#ed2f47 21.04%,#f79aa6 51.69%,#ffe8eb 86.88%);
  border-radius: 90px;
  height: 90px;
  width: 90px;
}
.who-for-inner .textPlusIcon{
  top: 22px;
  position: relative;
}
.who-for-inner .textPlusIcon .aboutText{
  border-radius: 11px;
  border: 1px solid #f4f4f4;
  background: linear-gradient(93deg, #f7f5fd 5.15%, #fefdff 29.58%, #fefdff 71.42%, #f8f0fd 94.87%);
  padding: 30px 10px;
  font-weight: 500;
  line-height: 1.3;
  width: 85%;
  margin-bottom: 0;
}
.who-for-inner .textPlusIcon .aboutText p{
  font-size: 15px;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
button.btn.clickToShowMore  span{
  font-size: 10px;
  color: #8e8bf5;
  font-weight: bold;

}

.all-products-lists .payment-link{
  display: none !important;
}
.payment-link.show {
  display: block;
}

.bonus-book img{
  height: 300px;
}

.fakeAlert {
  position: fixed;
  top: 85%;
  left: 15%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: white;
  color: blsck;
  border-radius: 5px;
  z-index: 9999;
  width: 90%;
  border: 1px solid rgba(182, 182, 182, 0.636);

}

.product-included{
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border: 1px solid #ebeaea;
}
.product-included .product-list{
  height: 350px ;
  scrollbar-width: thin; 
  scrollbar-color: #999 #f0f0f0; 
}

.product-included .product-list::-webkit-scrollbar {
  width: 10px; 
}

.product-included .product-list::-webkit-scrollbar-track {
  background: #f0f0f0; 
}

.product-included .product-list::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 5px; 
}
.product-included .productCount{
  background-color: #ebeaea;
  border-radius: 35px;
}
.product-included .product-list .productPriceImg {
border-bottom: 1px solid #ebeaea;
padding: 15px;
}
.product-included .product-list .productetail img{
  width: 75px;
  border-radius: 7px;
}

@media (max-width: 768px) {
  .fakeAlert {
    left: 50%;
  }
  .title h2{
    font-size: 10px;
  }
  .bonus-book img{
    height: 250px !important;
  }
  .bread-cramb-path p{
    font-size: 10px;
  }
  .percentage-off {
    position: absolute;
    right: 2%;
    font-size: 15px;
    top: 15%;
    z-index: 15;
  }
  .active-ordered {
    justify-content: space-between;
    margin-top: 8px;
  }
  .active-ordered div {
    font-size: x-small;;
    width: 50%;
    margin: 5px !important;
  }
  .main-image-crousel img {
    height: 230px;
  }
  .ebook-description{
    padding: 0px  !important;
  }


  .ebook-description p h6{
    font-size: 13px;
  }
  .price-title-off h2{
    font-size: larger;
  }

  .ebook-description .hurry-up img {
    height: 15px;
    width: 15px;
  }
  
  .each-detail-product .each-title {
    font-size: larger;
  }
  .product-cards {
    margin: 65px 0px !important;
  }
  button.btn.clickToShowMore {
    margin-top: -86px;
  }
  .payment-bottom-pc{
    display: none !important;
  }
  .all-products-lists .payment-link{
    display: flex !important;
  }
  .payment-link button {
    font-size: small;
  }
  .container{
    padding: 0px !important;
  }
  .owl-carousel.owl-drag .owl-item {
    width: 350px;
  }
  .preview-container img{
    height: 85px !important;
  }
  .owl-carousel .owl-item img{
    height: 235px;
    /* transform: scale(2);  */
  }
  img.zoomable.selected{
    /* transform: scale(2);  */
  }
  .crousel-each-product  .owl-stage .active  img{
  transform: scale(2); 
}
.crousel-each-product .owl-stage .active .selected{
   transform: scale(1); 

 } 
 .product-cards ul{
   display: block;
  }
  .product-cards ul li{
    width: 100%;
    font-size: 15px;
    font-weight: 500;
  }
}
