@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
.navbar-container{
    position: sticky;
    top: 0;
    z-index: 555555;
}
.brand-name{
    font-family: "Protest Riot", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #8e8bf5;
  font-size: large;
}

.nav-item .nav-link{
    color: black;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.nav-item .nav-link:hover{
    color: #8e8bf5;
}