@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

.topshow-crousel-div {
  width: 100%;
  height: 550px !important;
  background-image: url("http://tinyurl.com/mvz2hp89");
}
.product-area {
  height: 550px;
}

.product-images img {
    height: 70%;
    width: 60% !important;
    border-radius: 15px;
    transform: rotate(45deg); 
  }
  .welcome-offer h3{
    font-family: "Cedarville Cursive", cursive;
  font-weight: bolder;
  font-style: normal;
  color: #8e8bf5;
  }
  .welcome-offer  span{
      color: #8e8bf5;
  }
  .welcome-offer h2{
   font-size: 45px;
  }

  .welcome-offer button{
    background-color: #8e8bf5;
    border: none;
    color: white;
    font-size: 17px;
    padding: 7px 10px;
    border-radius: 6px;
  }

  @media (max-width: 768px) {
    .product-images img {
      height: 100%;
      transform: rotate(2deg);
    }
    .product-area {
      height: 100%;
    }
    .topshow-crousel-div {
      height: 100% !important;
    }
    .welcome-offer h2 {
      font-size: 20px;
    }
    .welcome-offer{
      padding-top: 5px;
    }
    .welcome-offer h3 {
      font-size: 15px;
    }
    .owl-dots{
      display: none;
    }
    .welcome-offer button {
      margin: 0 !important;
    }
  }