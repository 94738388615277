.showcase{
    /* background-image: url("http://tinyurl.com/y4vdmv26"); */
}
.ebooks-card{
    border: 1px solid #80808026;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    cursor: pointer;
    background-color: white;
}
.ebooks-card:hover img{
    transform: scale(1.1);
    transition: transform 0.3s ease;
}
.ebooks-card img{
    height: auto;
    width: 275px;
}
.ebooks-card .sale{
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    letter-spacing: .1em;
    z-index: 1;
    background: #8e8bf5;
    padding: 10px;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    box-shadow: -1px -2px 13px #565656;
    color: white;
}

.get-ebooks-info{
background-color: #8e8bf5;
display: flex;
color: white;
justify-content: center;
}

.category-nav h3{
   font-size: 17px;
    background-color: white;
    padding: 10px;
    border-radius: 11px;
    box-shadow: 1px 1px 10px gray;
    cursor: pointer;
}
.category-nav h3:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.category-nav .active-category{
    background-color: #8e8bf5;
    color: white;
}

.deals-img {
    background-image: url("https://ps-booksbuy.myshopify.com/cdn/shop/files/banner-01_570x.jpg?v=1632218917");
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
  }
  
  .shop-btn {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    text-align: center;
  }
  
  .shop-btn p {
    margin-bottom: 10px;
  }
  
  .shop-btn button {
    background-color: #8e8bf5;
    border-radius: 11px;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .shop-btn button:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    background-color: #7773f1;
  }
  